const { web3 } = require('../web3');
const { env } = require('../config');

const NFTData = require(`./${env}/NFT.json`);
const GasMineData = require(`./${env}/GasMine.json`);
const VikingNFTData = require(`./${env}/VikingNFT.json`);
const usdt = require(`./${env}/USDT.json`);
const DAOData = require(`./${env}/DAO.json`);
const DAOMultiSignatureData = require(`./${env}/DAOMultiSignature.json`);
const DAOFactoryData = require(`./${env}/DAOFactory.json`);
const NFTFactoryData = require(`./${env}/NFTFactory.json`);
const TokenData = require(`./${env}/HBurnToken.json`);

const HBurnFactoryData = require(`./${env}/HBurnFactory.json`);
const HBurnRouterData = require(`./${env}/HBurnRouter.json`);
const HBurnPairData = require(`./${env}/HBurnPair.json`);
const ERC20Data = require(`./${env}/ERC20.json`);
const AssetsNFT = require(`./${env}/AssetsNFT.json`);

const TokenStaking = require(`./${env}/HBurnTokenStaking.json`);
const NFTMarketpalce = require(`./${env}/HBurnMarketpalce.json`);
// const seedRound = require(`./${env}/SEEDROUND.json`);
// const NFTStaking = require(`./${env}/HBurnPatnerNFTStaking.json`);


const VikingNFTABI = VikingNFTData['abi'];
const VikingNFTAddress = VikingNFTData['address'];
const VikingNFTContract = new web3.eth.Contract(VikingNFTABI, VikingNFTAddress);

const AssetsNFTABI = AssetsNFT['abi'];
const AssetsNFTAddress = AssetsNFT['address'];
const AssetsNFTContract = new web3.eth.Contract(AssetsNFTABI, AssetsNFTAddress);

const NFTABI = NFTData['abi'];
const NFTAddress = NFTData['address'];
const NFTContract = new web3.eth.Contract(NFTABI, NFTAddress);

const GasMineABI = GasMineData['abi'];
const GasMineAddress = GasMineData['address'];
const GasMineContract = new web3.eth.Contract(GasMineABI, GasMineAddress);

const USDTABI = usdt['abi'];
const USDTAddress = usdt['address'];
// const USDTContract = new web3.eth.Contract(USDTABI, USDTAddress);

const DAOABI = DAOData['abi'];
const DAOAddress = DAOData['address'];
const DAOContract = new web3.eth.Contract(DAOABI, DAOAddress);

const DAOMultiSignatureABI = DAOMultiSignatureData['abi'];
const DAOMultiSignatureBytecode = DAOMultiSignatureData['bytecode'];

const TokenABI = TokenData['abi'];
const TokenAddress = TokenData['address'];
const TokenContract = new web3.eth.Contract(TokenABI, TokenAddress);

const HBurnFactoryABI = HBurnFactoryData['abi'];
const HBurnFactoryAddress = HBurnFactoryData['address'];
const HBurnFactoryContract = new web3.eth.Contract(HBurnFactoryABI, HBurnFactoryAddress);

const HBurnRouterABI = HBurnRouterData['abi'];
const HBurnRouterAddress = HBurnRouterData['address'];
const HBurnRouterContract = new web3.eth.Contract(HBurnRouterABI, HBurnRouterAddress);

const HBurnPairABI = HBurnPairData['abi'];

const ERC20ABI = ERC20Data['abi'];
const ERC20Bytecode = ERC20Data['bytecode'];

const DAOFactoryABI = DAOFactoryData['abi'];
const DAOFactoryBytecode = DAOFactoryData['bytecode'];

const NFTFactoryABI = NFTFactoryData['abi'];
const NFTFactoryBytecode = NFTFactoryData['bytecode'];

///////////////////////////////////////////////////////////////////////////////////////////

const NFTMarketpalceABI = NFTMarketpalce['abi'];
const NFTMarketpalcAddress = NFTMarketpalce['address'];
const NFTMarketpalcContract = new web3.eth.Contract(NFTMarketpalceABI, NFTMarketpalcAddress);

const TokenStakingABI = TokenStaking['abi'];
const TokenStakingAddress = TokenStaking['address'];
const TokenStakingContract = new web3.eth.Contract(TokenStakingABI, TokenStakingAddress);

// const SeedRountABI = seedRound['abi'];
// const SeedRountAddress = seedRound['address'];
// const SeedRountEthAddress = seedRound['addressEth'];
// const SeedRountContract = new web3.eth.Contract(SeedRountABI, SeedRountAddress);

// const NFTStakingABI = NFTStaking['abi'];
// const NFTStakingAddress = NFTStaking['address'];
// const NFTStakingContract = new web3.eth.Contract(NFTStakingABI, NFTStakingAddress);

///////////////////////////////////////////////////////////////////////////////////////////


module.exports = {
  NFTContract, NFTABI, NFTAddress,
  USDTAddress, USDTABI,
  DAOABI, DAOAddress, DAOContract,
  TokenABI, TokenAddress, TokenContract,
  HBurnFactoryABI, HBurnFactoryAddress, HBurnFactoryContract,
  HBurnRouterABI, HBurnRouterAddress, HBurnRouterContract,
  HBurnPairABI, ERC20ABI, ERC20Bytecode, DAOFactoryABI, DAOFactoryBytecode,
  VikingNFTABI, VikingNFTAddress, VikingNFTContract,
  DAOMultiSignatureABI, DAOMultiSignatureBytecode,
  GasMineABI, GasMineAddress, GasMineContract,
  NFTMarketpalceABI, NFTMarketpalcAddress, NFTMarketpalcContract,
  TokenStakingABI, TokenStakingAddress, TokenStakingContract,
  AssetsNFTABI, AssetsNFTAddress, AssetsNFTContract,
  NFTFactoryABI, NFTFactoryBytecode
  // NFTStakingABI, NFTStakingAddress, NFTStakingContract,
  // SeedRountABI, SeedRountAddress, SeedRountEthAddress,
};