import React, { Component, lazy, Suspense } from 'react';
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Error from '@material-ui/icons/Error';
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from 'react-toastify';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { tokenVerify } from "../store/jwtVerify";

import { web3 } from "../store/web3";
// import HBurnMarketplace from './HBurnMarketplace';
import Loader from '../components/loader';
// import { networkId, message } from "../store/config";
import { logout, login } from '../store/actions/Auth';

import '../static/css/style.css';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-toastify/dist/ReactToastify.css";

import LoaderComponent from "../components/loader/loader";
import AssetsNFT from './AssetsNFT';

// Lazy load the components
const HBurnMarketplace = lazy(() => import('./HBurnMarketplace'));
const SeedRound = lazy(() => import('./SeedRound'));
const Explore = lazy(() => import('./Explore'));
const MintNFT = lazy(() => import('./MintNFT'));
const Minting = lazy(() => import('./Minting'));
const MintAssetsNFT = lazy(() => import('./MintAssetsNFT'));
const PrivateSale = lazy(() => import('./PrivateSale'));
const DAOProposals = lazy(() => import('./DAOProposals'));
const BuyNFT = lazy(() => import('./BuyNFT'));
const EditProfile = lazy(() => import('./EditProfile'));
const Collection = lazy(() => import('./Collection'));
const Profile = lazy(() => import('./Profile'));
const Swap = lazy(() => import('./Swap'));
const Staking = lazy(() => import('./Staking'));
const CreateNFT = lazy(() => import('./CreateNFT'));
const ViewNFT = lazy(() => import('./ViewNFT'));
const CreateToken = lazy(() => import('./CreateToken'));
const ViewToken = lazy(() => import('./ViewToken'));
const WhitelistPFP = lazy(() => import('./WhitelistPFP'));
const Whitelist = lazy(() => import('./Whitelist'));
const LaunchDAO = lazy(() => import('./LaunchDAO'));
const DAOSteps = lazy(() => import('./DAOSteps'));
const Leaderboard = lazy(() => import('./Leaderboard'));
const ViewDAO = lazy(() => import('./ViewDAO'));
const SubmitProposal = lazy(() => import('./SubmitProposal'));
const Dashboard = lazy(() => import('./Dashboard'));
// const SwapHashport = lazy(() => import('./SwapHashport'));


const hist = createBrowserHistory();
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  };

  componentDidMount() {
    // this.checkEthereum();
    EventBus.on('tokenExpired', () => this.props.logout());
    // if (!tokenVerify()) EventBus.on("tokenExpired", () => this.props.logout());
    EventBus.on('info', (e) => toast.info(() => <div> <Error /> {e}</div>));
    EventBus.on('error', (e) => toast.error(() => <div> <Error /> {e}</div>));
    EventBus.on('success', (e) => toast.success(() => <div> <CheckCircle /> {e}</div>));
  };


  // checkEthereum = async () => {
  //   if (typeof window.ethereum !== "undefined") {

  //     // check network
  //     web3.eth.net.getId((err, netId) => {
  //       if (netId != networkId) {
  //         EventBus.publish('info', message);
  //       }
  //     });

  //     window.ethereum.on("accountsChanged", accounts => {
  //       this.props.logout();
  //       EventBus.publish("info", "Account has been changed");
  //     });

  //     window.ethereum.on("networkChanged", netId => {
  //       this.props.logout();
  //       window.location.reload();
  //       EventBus.publish("info", "Network has been changed");
  //     });
  //   }
  // };

  render() {

    return (
      <div>
        <Loader />
        <ToastContainer
          closeOnClick
          position="bottom-left"
        />
        <Suspense fallback={<LoaderComponent message="Loading..."/>}>
        <Router history={hist}>
          <Switch>
            <Route exact path='/' component={HBurnMarketplace} />
            <Route exact path='/seed-round' component={SeedRound} />
            <Route exact path='/marketplace' component={Explore} />
            <Route exact path='/mint-partner-nft' component={MintNFT} />
            <Route exact path='/mint-hederagon-nft' component={Minting} />
            <Route exact path='/assets-nft' component={AssetsNFT} />
            <Route exact path='/private-sale' component={PrivateSale} />
            {/* <Route exact path='/dao' component={DAO} /> */}
            <Route exact path='/hburn-dao' component={DAOProposals} />
            <Route exact path='/buy-nft/:id' component={BuyNFT} />
            <Route exact path='/edit-profile' component={EditProfile} />
            <Route exact path='/collection/:chain/:tokenAddress' component={Collection} />
            <Route exact path='/profile' component={Profile} />
            <Route exact path='/swap' component={Swap} />
            <Route exact path='/staking' component={Staking} />
            <Route exact path='/create-token' component={CreateToken} />
            <Route exact path='/create-nft' component={CreateNFT} />
            <Route exact path='/view-token' component={ViewToken} />
            <Route exact path='/view-nft' component={ViewNFT} />
            <Route exact path='/whitelistPFP' component={WhitelistPFP} />
            <Route exact path='/whitelist' component={Whitelist} />
            <Route exact path='/launch-dao' component={LaunchDAO} />
            <Route exact path='/dao-steps' component={DAOSteps} />
            <Route exact path='/leaderboard' component={Leaderboard} />
            <Route exact path='/view-dao' component={ViewDAO} />
            <Route exact path='/dao-details/:daoAddress' component={SubmitProposal} />
            <Route exact path='/dashboard' component={Dashboard} />
            <Route exact path='/asset-nft-mint' component={MintAssetsNFT} />
            
            {/* <Route exact path='/brigde' component={SwapHashport} /> */}
          </Switch>
        </Router>
        </Suspense>

      </div>
    );
  }
}

const mapDispatchToProps = {
  logout, login
};

const mapStateToProps = ({ Auth }) => {
  let { auth } = Auth;
  return { auth }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
