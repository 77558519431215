/* -- set app title --*/
const AppTitle = 'Hburn Frontend';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['development'];

/* -- set API URLs --*/
const testing = 'https://dev.server.hburn.co';
const production = 'https://www.prod.server.hburn.co';
// const development = 'http://localhost:4000';
const development = 'https://dev.server.hburn.co';

let SocketUrl;
let env = AppMode[0] || 'development', networkId = '', message = '', explorer = '', networkIdEth = '', networkType = '';

switch (AppMode[0]) {
  case 'development':
    networkId = 37084624;
    networkIdEth = 11155111;
    networkType = true;
    SocketUrl = development;
    explorer = 'https://lanky-ill-funny-testnet.explorer.testnet.skalenodes.com/'
    message = 'Please switch your network to Skale Nebula Testnet';
    break;
  case 'production':
    networkId = 43114;
    networkIdEth = 1;
    networkType = false;
    SocketUrl = production;
    message = 'Please switch your network to Avalanche C-Chain';
    explorer = 'https://snowtrace.io/'
    break;
  case 'testing':
    networkId = 37084624;
    SocketUrl = testing;
    networkType = true;
    explorer = 'https://lanky-ill-funny-testnet.explorer.testnet.skalenodes.com/'
    message = 'Please switch your network to Skale Nebula Testnet';
    break;
  default:
    networkId = 37084624;
    networkIdEth = 11155111;
    networkType = true;
    SocketUrl = 'http://localhost:4000';
    explorer = 'https://lanky-ill-funny-testnet.explorer.testnet.skalenodes.com/'
    message = 'Please switch your network to Skale Nebula Testnet';
}

let ApiUrl = `${SocketUrl}/api`;
export { AppTitle, ApiUrl, SocketUrl, networkId, message, explorer, networkIdEth, env, networkType };