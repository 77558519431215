import Web3 from 'web3';
import { networkId } from './config';
export const connectMetamask = async (network, isTestnet) => {
  try {
    // Check if Metamask is installed
    if (typeof window.ethereum !== 'undefined') {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.enable();

      let x = await web3.currentProvider.chainId;
      if (!x) {
        return Promise.reject(new Error({ message: `Please Install Metamask!!!` }));
      }      
      // Define network details
      let rpcUrl, chainId, chainName, nativeCurrency, blockExplorerUrl;
      switch (network) {
        case 'avalanche':
          rpcUrl = isTestnet
            ? ['https://api.avax-test.network/ext/bc/c/rpc']
            : ['https://api.avax.network/ext/bc/C/rpc']
          chainId = isTestnet ? '0xa869' : '0xa86a';
          chainName = isTestnet ? 'Avalanche Fuji Testnet' : 'Avalanche C-Chain';
          nativeCurrency = {
            name: isTestnet ? 'Avalanche Fuji Testnet' : 'Avalanche C-Chain',
            symbol: isTestnet ? 'AVAX' : 'AVAX',
            decimals: 18,
          };
          blockExplorerUrl = isTestnet
            ? 'https://testnet.snowtrace.io/'
            : 'https://snowtrace.io/';
          break;

        case 'skale':
          rpcUrl = isTestnet
            ? ['https://testnet.skalenodes.com/v1/lanky-ill-funny-testnet']
            : ['https://mainnet.skalenodes.com/v1/green-giddy-denebola']
          chainId = isTestnet ? '0x235ddd0' : '0x585eb4b1';
          chainName = isTestnet ? 'SKALE Nebula Hub Testnet' : 'SKALE Nebula Hub';
          nativeCurrency = {
            name: isTestnet ? 'SKALE Nebula Hub Testnet' : 'SKALE Nebula Hub',
            symbol: isTestnet ? 'sFUEL' : 'sFUEL',
            decimals: 18,
          };
          blockExplorerUrl = isTestnet
            ? 'https://lanky-ill-funny-testnet.explorer.testnet.skalenodes.com/'
            : 'https://green-giddy-denebola.explorer.mainnet.skalenodes.com/';
          break;

        default:
          return Promise.reject(new Error({ message: 'Invalid network' }));
      }
      const netId = await web3.eth.net.getId();
      if (isTestnet && netId !== 37084624) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: chainId,
              chainName: chainName,
              nativeCurrency: nativeCurrency,
              rpcUrls: rpcUrl,
              blockExplorerUrls: [blockExplorerUrl],
            },
          ],
        });
      } else if (!isTestnet && netId !== 43114) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: chainId,
              chainName: chainName,
              nativeCurrency: nativeCurrency,
              rpcUrls: rpcUrl,
              blockExplorerUrls: [blockExplorerUrl],
            },
          ],
        });
      }
      else {
        // Switch to the selected network
        // await web3.eth.switchNetwork(chainId);
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        });
      }

      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      return Promise.resolve({ address });

    } else {
      console.log('Metamask is not installed');
      return Promise.reject(new Error({ message: 'Metamask is not installed' }));
    }
  } catch (error) {
    console.log('Error connecting to Metamask:', error);
    return Promise.reject(error.message);
  }
};

export const getNetworkSymbol = (networkId) => {
  const networkMap = {
    '43114': 'avalanche', // Avalanche Mainnet
    '43113': 'avalanche', // Avalanche Testnet
    '1482601649': 'skale', // Skale Nebula Mainnet
    '37084624': 'skale', // Skale Nebula Testnet
  };

  return networkMap[networkId] || 'Unknown';
};